<template>
  <div id="app">
    <Tabs></Tabs>
    <div class="content_box">
      <div class="content">
        <!-- 标题 -->
        <div class="title_box">
          <span>{{ paper.project_title }}</span
          ><span>&nbsp;>&nbsp;</span><span>历年真题</span
          ><span>&nbsp;>&nbsp;</span><span>{{ paper.paper_title }}</span>
        </div>
        <div class="introduce_box">
          <!-- 二维码 -->
          <div class="code_box">
            <span>手机做题</span>
            <div>
              扫码下载
              <p>APP</p>
              &nbsp;&nbsp;&nbsp;随时随地刷题
            </div>
            <div class="line"></div>
            <div class="code_img">
              <img :src="code" alt="" />
            </div>
          </div>
          <div class="introduce_left">
            <!-- 试卷名字 -->
            <div class="paper_name">{{ paper.paper_title }}</div>
            <!-- 试卷介绍 -->
            <div class="paper_info">
              <div class="info_content">
                <!-- 顶部 -->
                <ul>
                  <li>
                    <img src="../../../../assets/img/leixing.png" alt="" />
                    <div>
                      <p>试题类型：</p>
                      <p>真题试卷</p>
                    </div>
                  </li>
                  <li>
                    <img src="../../../../assets/img/xiangmu.png" alt="" />
                    <div>
                      <p>所属项目：</p>
                      <p>{{ paper.project_title }}</p>
                    </div>
                  </li>
                  <li>
                    <img src="../../../../assets/img/kemu (2).png" alt="" />
                    <div>
                      <p>所属科目：</p>
                      <p>{{ paper.subject_title }}</p>
                    </div>
                  </li>
                  <li>
                    <img src="../../../../assets/img/nianfen.png" alt="" />
                    <div>
                      <p>试题年份</p>
                      <p>{{ paper.subject_title }}</p>
                    </div>
                  </li>
                  <li>
                    <img src="../../../../assets/img/defen.png" alt="" />
                    <div>
                      <p>试卷总分</p>
                      <p>{{ paper.total_score }}</p>
                      <!-- <p>中药学综合知识与技能</p> -->
                    </div>
                  </li>
                  <li>
                    <img src="../../../../assets/img/time.png" alt="" />
                    <div>
                      <p>答题时间</p>
                      <p>{{ paper.time_length / 60 }}分钟</p>
                    </div>
                  </li>
                </ul>
                <!-- 开始做题 -->
                <div class="info_down">
                  <div>
                    <img src="../../../../assets/img/1.png" alt="" />
                    <img src="../../../../assets/img/2 (2).png" alt="" />
                    <img src="../../../../assets/img/3 (2).png" alt="" />
                    <img src="../../../../assets/img/4.png" alt="" />
                    <p>{{ paper.people_nums }}人做过</p>
                  </div>
                  <button type="button">
                    开始考试
                    <!-- <img src="../../../../assets/4.png" alt="" /> -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  props: {},
  data() {
    return {
      paper: {}, // 试卷详情
      code: "", // 二维码
    };
  },
  computed: {},
  beforeCreate() {},
  created() {
    this.getPaper();
    this.getCode();
  },
  mounted() {},
  watch: {},
  methods: {
    // 获取试卷
    getPaper() {
      let data = {
        chapter_category: this.$route.params.chapter_id,
        paper_id: this.$route.params.paper_id, // 试卷id
        //
      };
      api.test_papers(data).then((res) => {
        if (res.data.code == 1) {
          this.paper = res.data.data;
          // console.log(res.data.data);
        }
        // console.log("试卷11111", res);
        // this.$axios({
        //   method: "get",
        //   url: "https://res-qd.liyouedu.cn/resources/papers/20210719/3_1626663652.json",
        // }).then((response) => {
        //   // console.log("11111",response); //请求成功返回的数据
        // });
      });
    },
    // 获取二维码
    getCode() {
      api.download_qrcode().then((res) => {
        // console.log("二维码", res);
        if (parseInt(res.data.code) == 1) {
          this.code = res.data.data.qrcode;
        }
      });
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
#app {
  // background: #f5f5f5;
  // 内容区
  .content_box {
    width: 100%;
    height: 100vh;
    background: #f5f5f5;
    // padding-bottom: 300px;
    .content {
      width: 1200px;
      margin: auto;
      overflow: hidden;
      // 标题
      .title_box {
        margin: 28px 0 24px;
        display: flex;
        span {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #484848;
        }
      }
      // 介绍
      .introduce_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        // 二维码
        .code_box {
          width: 310px;
          height: 354px;
          background: #fff;
          display: flex;
          flex-flow: column;
          align-items: center;
          span {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin: 21px 0 8px;
          }
          div:nth-child(2) {
            display: flex;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            p {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #3e7eff;
            }
          }
          .line {
            width: 260px;
            height: 1px;
            // border: 1px solid #f5f5f5;
            background: #f5f5f5;
            border-radius: 1px;
            margin: 20px 0 37px;
          }
          .code_img {
            width: 189px;
            height: 188px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("../../../../assets/img/kuang.png") no-repeat;
            background-size: 189px 188px;
            img {
              width: 162px;
              height: 162px;
            }
          }
        }
        .introduce_left {
          width: 880px;
          height: 550px;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          // 试卷名字
          .paper_name {
            width: 100%;
            height: 76px;
            background: #fff;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 76px;
            text-align: center;
          }
          // 试卷介绍
          .paper_info {
            width: 100%;
            height: 464px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            .info_content {
              width: 700px;
              height: 304px;
              background: #fafbfd;
              border-radius: 10px;
              padding: 50px 60px;
              display: flex;
              flex-flow: column;
              justify-content: space-between;
              // 顶部
              ul {
                width: 700px;
                display: flex;
                justify-content: space-between;

                flex-wrap: wrap;
                // margin-top: 50px;
                li {
                  width: 200px;
                  display: flex;
                  align-items: center;
                  margin-bottom: 40px;
                  img {
                    width: 60px;
                    height: 60px;
                    margin-right: 24px;
                  }
                  div {
                    p:nth-child(1) {
                      font-size: 14px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #666666;
                    }
                    p:nth-child(2) {
                      font-size: 18px;
                      font-family: PingFang SC;
                      font-weight: 500;
                      color: #222222;
                    }
                  }
                }
              }
              // 开始做题
              .info_down {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                div {
                  display: flex;
                  align-items: center;
                  img {
                    width: 40px;
                    height: 40px;
                    margin-right: 8px;
                  }
                  p {
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    margin-left: 6px;
                  }
                }
                button {
                  width: 220px;
                  height: 50px;
                  background: #3e7eff;
                  border-radius: 8px;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
